import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

function PrivateRoute({ auth: { isAuthenticated, loading }, children }) {
  const navigate = useNavigate();
  return !isAuthenticated && !loading ? navigate("/") : children;
}

PrivateRoute.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.element,
};

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps)(PrivateRoute);
