import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getConferences } from "../../../services/actions/conferences";
import AdminConferenceForm from "../../../container/AdminConferenceForm";
import AdminConferenceTable from "../../../container/AdminConferenceTable";
import Sidebar from "../../../layout/Sidebar";

const AdminConferencePage = ({ getConferences, conferences: { conferences, loading } }) => {
  useEffect(() => {
    getConferences();
  }, [getConferences]);

  const [editConferenceData, setEditConferenceData] = useState();
  return loading || conferences === null ? (
    <div className="loader"></div>
  ) : (
    <Fragment>
      <Sidebar>
        <AdminConferenceForm editConferenceData={editConferenceData} />
        <AdminConferenceTable conferences={conferences} setEditConferenceData={setEditConferenceData} />
      </Sidebar>
    </Fragment>
  );
};

AdminConferencePage.propTypes = {
  getConferences: PropTypes.func.isRequired,
  conferences: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  conferences: state.conferences,
});

export default connect(mapStateToProps, { getConferences })(AdminConferencePage);
