import {
  ABOUT_SECTION_ERROR,
  GET_ABOUT_SECTION,
  EDIT_ABOUT_SECTION,
} from "./types";
import axios from "axios";
import toast from "react-hot-toast";

//Get About section data
export const getAboutSection = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_VERCEL_URL}/about`);
    dispatch({
      type: GET_ABOUT_SECTION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ABOUT_SECTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Edit About Section
export const editAboutSection = (aboutData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_VERCEL_URL}/about/edit`,
      aboutData
    );
    dispatch({
      type: EDIT_ABOUT_SECTION,
      payload: res.data,
    });
    toast.success("Updated successfully.");
  } catch (err) {
    toast.error("Error updating details, please try again.");
    dispatch({
      type: ABOUT_SECTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
