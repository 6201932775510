import {
  ADD_PROJECT,
  DELETE_PROJECT,
  GET_PROJECTS,
  PROJECT_ERROR,
} from "../actions/types";

const initialState = {
  projects: [],
  project: null,
  loading: true,
  error: {},
};

export default function projects(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_PROJECT:
      return {
        ...state,
        projects: [payload, ...state.projects],
        loading: false,
      };
    case DELETE_PROJECT:
      return {
        ...state,
        projects: state.projects.filter((project) => project._id !== payload),
        loading: false,
      };
    case GET_PROJECTS:
      return {
        ...state,
        projects: payload,
        loading: false,
      };
    case PROJECT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
