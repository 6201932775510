import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getBooks } from "../../../services/actions/books";
import AdminBookForm from "../../../container/AdminBookForm";
import BookTable from "../../../container/AdminBookTable";
import Sidebar from "../../../layout/Sidebar";

const AdminBooksPage = ({ getBooks, books: { books, loading } }) => {
  useEffect(() => {
    getBooks();
  }, [getBooks]);

  const [editBookData, setEditBookData] = useState();
  return loading || books === null ? (
    <div className="loader"></div>
  ) : (
    <Fragment>
      <Sidebar>
        <AdminBookForm editBookData={editBookData} />
        <BookTable books={books} setEditBookData={setEditBookData} />
      </Sidebar>
    </Fragment>
  );
};

AdminBooksPage.propTypes = {
  getBooks: PropTypes.func.isRequired,
  books: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  books: state.books,
});

export default connect(mapStateToProps, { getBooks })(AdminBooksPage);
