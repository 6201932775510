import {
  ADD_BLOG,
  DELETE_BLOG,
  GET_BLOG,
  GET_BLOGS,
  BLOG_ERROR,
} from "../actions/types";

const initialState = {
  blogs: [],
  blog: null,
  loading: true,
  error: {},
};

export default function blogs(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_BLOG:
      return {
        ...state,
        blogs: [payload, ...state.blogs],
        loading: false,
      };
    case DELETE_BLOG:
      return {
        ...state,
        blogs: state.blogs.filter((blog) => blog._id !== payload),
        loading: false,
      };
    case GET_BLOGS:
      return {
        ...state,
        blogs: payload,
        loading: false,
      };
    case GET_BLOG:
      return {
        ...state,
        blog: payload,
        loading: false,
      };
    case BLOG_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
