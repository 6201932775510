export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const ADD_BOOK = "ADD_BOOK";
export const DELETE_BOOK = "DELETE_BOOK";
export const GET_BOOKS = "GET_BOOKS";
export const BOOKS_ERROR = "BOOKS_ERROR";
export const ADD_BOOK_CHAPTERS = "ADD_BOOK_CHAPTERS";
export const DELETE_BOOK_CHAPTERS = "DELETE_BOOK_CHAPTERS";
export const GET_BOOK_CHAPTERS = "GET_BOOK_CHAPTERS";
export const BOOK_CHAPTERS_ERROR = "BOOK_CHAPTERS_ERROR";
export const ADD_JOURNAL_ARTICLE = "ADD_JOURNAL_ARTICLE";
export const DELETE_JOURNAL_ARTICLE = "DELETE_JOURNAL_ARTICLE";
export const GET_JOURNAL_ARTICLES = "GET_JOURNAL_ARTICLES";
export const JOURNAL_ARTICLE_ERROR = "JOURNAL_ARTICLE_ERROR";
export const ADD_CONFERENCE = "ADD_CONFERENCE";
export const DELETE_CONFERENCE = "DELETE_CONFERENCE";
export const GET_CONFERENCES = "GET_CONFERENCES";
export const CONFERENCE_ERROR = "CONFERENCE_ERROR";
export const ADD_PROJECT = "ADD_PROJECTSE";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const GET_PROJECTS = "GET_PROJECTS";
export const PROJECT_ERROR = "PROJECT_ERROR";
export const ADD_BLOG = "ADD_BLOG";
export const DELETE_BLOG = "DELETE_BLOG";
export const GET_BLOG = "GET_BLOG";
export const GET_BLOGS = "GET_BLOGS";
export const BLOG_ERROR = "BLOG_ERROR";
export const ADD_LINKS = "ADD_LINKS";
export const DELETE_LINKS = "DELETE_LINKS";
export const GET_LINKS = "GET_LINKS";
export const GET_LINK = "GET_LINK";
export const LINKS_ERROR = "LINKS_ERROR";
export const GET_ABOUT_SECTION = "GET_ABOUT_SECTION";
export const EDIT_ABOUT_SECTION = "EDIT_ABOUT_SECTION";
export const ABOUT_SECTION_ERROR = "ABOUT_SECTION_ERROR";
