import { connect } from "react-redux";
import PropTypes from "prop-types";
import VerticalNavigation from "../layout/VerticalNavigation";
import EmptyComponent from "./EmptyComponent";

function BookChaptersPageContainer({ bookChapters }) {
  return (
    <VerticalNavigation>
      <div className="px-12">
        <div className="mx-auto grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-1">
          {bookChapters.map((bookChapter) => (
            <div
              key={bookChapter._id}
              className="flex flex-col overflow-hidden rounded-lg shadow-lg"
            >
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <p className="text-sm font-medium text-sky-600">
                    <a
                      href={bookChapter.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      nore
                      className="hover:underline"
                    >
                      View
                    </a>
                  </p>
                  <div className="mt-2 block">
                    <p className="text-xl font-semibold text-gray-900 break-words">
                      {bookChapter.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500 break-words">
                      {bookChapter.description}
                    </p>
                  </div>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0"></div>
                  <div className="">
                    <div className="text-sm font-medium text-gray-900">
                      <p className="hover:underline break-words">
                        {bookChapter.author}
                      </p>
                    </div>
                    <div className="flex space-x-1 text-sm text-gray-500 break-words">
                      <p>Published: {bookChapter.releaseDate}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {bookChapters.length === 0 && (
          <EmptyComponent text="Book chapters coming soon" />
        )}
      </div>
    </VerticalNavigation>
  );
}

BookChaptersPageContainer.propTypes = {
  bookChapters: PropTypes.array.isRequired,
};

export default connect()(BookChaptersPageContainer);
