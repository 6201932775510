import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getConferences } from "../../services/actions/conferences";
import ConferencesContainer from "../../container/Conferences";

const Conferences = ({
  getConferences,
  conferences: { conferences, loading },
}) => {
  useEffect(() => {
    getConferences();
  }, [getConferences]);

  return loading || conferences === null ? (
    <div className="loader"></div>
  ) : (
    <Fragment>
      <ConferencesContainer conferences={conferences} />
      {/* <Contact /> */}
    </Fragment>
  );
};

Conferences.propTypes = {
  getConferences: PropTypes.func.isRequired,
  conferences: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  conferences: state.conferences,
});

export default connect(mapStateToProps, { getConferences })(Conferences);
