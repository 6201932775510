import { Controller } from 'react-hook-form';
import { Group } from './Group';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { classNames } from '../utils/helpers';

export const PasswordInput = ({
  passwordShown,
  togglePasswordVisibility,
  name,
  label,
  control,
  errors,
  ...rest
}) => {
  return (
    <Group label={label} errors={errors}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div className='mt-1 relative flex flex-grow items-stretch focus-within:z-10'>
            <input
              type='text'
              className={classNames(
                'form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white border border-solid border-gray-300 rounded-md transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-sky-500 focus:outline-none',
                {
                  'border-red-500': errors,
                }
              )}
              {...field}
              {...rest}
            />
            <div className='cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3'>
              {passwordShown ? (
                <EyeSlashIcon
                  onClick={togglePasswordVisibility}
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              ) : (
                <EyeIcon
                  onClick={togglePasswordVisibility}
                  className='h-5 w-5 text-gray-400'
                  aria-hidden='true'
                />
              )}
            </div>
          </div>
        )}
      />
    </Group>
  );
};
