import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProjects } from "../../services/actions/projects";
import ProjectsContainer from "../../container/Projects";

const Projects = ({ getProjects, projects: { projects, loading } }) => {
  useEffect(() => {
    getProjects();
  }, [getProjects]);

  return loading || projects === null ? (
    <div className="loader"></div>
  ) : (
    <Fragment>
      <ProjectsContainer projects={projects} />
    </Fragment>
  );
};

Projects.propTypes = {
  getProjects: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  projects: state.projects,
});

export default connect(mapStateToProps, { getProjects })(Projects);
