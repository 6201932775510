import axios from "axios";
import { ADD_BLOG, DELETE_BLOG, GET_BLOGS, BLOG_ERROR, GET_BLOG } from "./types";
import toast from "react-hot-toast";

//Get all blogs in the db
export const getBlogs = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_VERCEL_URL}/blogs`);
    dispatch({
      type: GET_BLOGS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BLOG_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Add blog
export const addBlog = (blogData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_VERCEL_URL}/blogs/add`,
      blogData
    );
    dispatch({
      type: ADD_BLOG,
      payload: res.data,
    });
    toast.success("Blog created successfully.");
  } catch (err) {
    dispatch({
      type: BLOG_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    toast.error("Error adding new blog.");
  }
};

//Delete blog
export const deleteBlog = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_VERCEL_URL}/blogs/${id}`);
    dispatch({
      type: DELETE_BLOG,
      payload: id,
    });
    toast.success("Blog deleted successfully.");
  } catch (err) {
    dispatch({
      type: BLOG_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    toast.error("Problem deleting blog, try again.");
  }
};


//Add blog
export const makeBlogCurrent = (blogId) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_VERCEL_URL}/blogs/set-current/${blogId}`);
    dispatch({
      type: ADD_BLOG,
      payload: res.data,
    });
    toast.success("Blog set to current on landing page.");
  } catch (err) {
    dispatch({
      type: BLOG_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    toast.error("Error making blog current.");
  }
};


//Get post detail
export const getBlog = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_VERCEL_URL}/blogs/${id}`);
    dispatch({
      type: GET_BLOG,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BLOG_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
