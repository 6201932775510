import axios from "axios";
import {
  ADD_JOURNAL_ARTICLE,
  DELETE_JOURNAL_ARTICLE,
  GET_JOURNAL_ARTICLES,
  JOURNAL_ARTICLE_ERROR,
} from "./types";
import toast from "react-hot-toast";

//Get all Journal Articles in the db
export const getJournalArticles = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_VERCEL_URL}/journalArticles`
    );
    dispatch({
      type: GET_JOURNAL_ARTICLES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: JOURNAL_ARTICLE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Add Journal Articles
export const addJournalArticle = (journalArticleData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_VERCEL_URL}/journalArticles/add`,
      journalArticleData
    );
    dispatch({
      type: ADD_JOURNAL_ARTICLE,
      payload: res.data,
    });
    toast.success("Journal Article created successfully.");
  } catch (err) {
    dispatch({
      type: JOURNAL_ARTICLE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    toast.error("Error adding new journal article.");
  }
};

//Delete journal article
export const deleteJournalArticle = (id) => async (dispatch) => {
  try {
    await axios.delete(
      `${process.env.REACT_APP_VERCEL_URL}/journalArticles/${id}`
    );
    dispatch({
      type: DELETE_JOURNAL_ARTICLE,
      payload: id,
    });
    toast.success("Journal Article deleted successfully.");
  } catch (err) {
    dispatch({
      type: JOURNAL_ARTICLE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    toast.error("Problem deleting journal article, try again.");
  }
};
