import axios from "axios";
import { ADD_LINKS, DELETE_LINKS, GET_LINKS, LINKS_ERROR } from "./types";
import toast from "react-hot-toast";

//Get all links in the db
export const getExternalLinks = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_VERCEL_URL}/links`);
    dispatch({
      type: GET_LINKS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: LINKS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Add link
export const addExternalLink = (linkData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_VERCEL_URL}/links/add`,
      linkData
    );
    dispatch({
      type: ADD_LINKS,
      payload: res.data,
    });
    toast.success("External link added successfully.");
  } catch (err) {
    dispatch({
      type: LINKS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    toast.error("Error creating new external link.");
  }
};

//Delete link
export const deleteLink = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_VERCEL_URL}/links/${id}`);
    dispatch({
      type: DELETE_LINKS,
      payload: id,
    });
    toast.success("External link deleted successfully.");
  } catch (err) {
    dispatch({
      type: LINKS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    toast.error("Problem deleting link, try again.");
  }
};
