import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getBlogs } from "../../services/actions/blogs";
import BlogsContainer from "../../container/Blogs";
import { getExternalLinks } from "../../services/actions/links";
const Blogs = ({
  getBlogs,
  getExternalLinks,
  blogs: { blogs, loading },
  externalLinks: { externalLinks, loading: loading2 },
}) => {
  useEffect(() => {
    getBlogs();
  }, [getBlogs]);

  useEffect(() => {
    getExternalLinks();
  }, [getExternalLinks]);

  return loading || loading2 || blogs === null || externalLinks === null ? (
    <div className="loader"></div>
  ) : (
    <Fragment>
      <BlogsContainer blogs={blogs} externalLinks={externalLinks} />
    </Fragment>
  );
};

Blogs.propTypes = {
  getBlogs: PropTypes.func.isRequired,
  getExternalLinks: PropTypes.func.isRequired,
  blogs: PropTypes.object.isRequired,
  externalLinks: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  blogs: state.blogs,
  externalLinks: state.externalLinks,
});

export default connect(mapStateToProps, { getExternalLinks, getBlogs })(Blogs);
