import { Input } from "../components/Input";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { externalLinksSectionSchema } from "../data";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addExternalLink } from "../services/actions/links";
import { useNavigate } from "react-router-dom";

function AdminExternalLinksForm({ addExternalLink, editExternalLinksData }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const defaultValues = {
    title: editExternalLinksData ? editExternalLinksData.title : "",
    link: editExternalLinksData ? editExternalLinksData.link : "",
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(externalLinksSectionSchema),
  });

  useEffect(() => {
    if (editExternalLinksData) {
      reset({
        title: editExternalLinksData.title,
        link: editExternalLinksData.link,
      });
    }
  }, [editExternalLinksData, reset]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (editExternalLinksData && editExternalLinksData._id) {
        const linksData = {
          id: editExternalLinksData._id,
          title: data.title,
          link: data.link,
        };
        await addExternalLink(linksData);
      } else {
        const linksData = {
          title: data.title,
          link: data.link,
        };
        await addExternalLink(linksData);
      }
      navigate(0);
    } catch (e) {
      console.log(e);
      toast.error("Error adding external link, please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div>
        <main className="py-12">
          <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
            <div className="overflow-hidden rounded-lg bg-white shadow">
              <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <form
                  className="divide-y divide-gray-200 lg:col-span-12"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="py-6 px-4 sm:p-6 lg:pb-8">
                    <div>
                      <h2 className="text-lg font-medium leading-6 text-gray-900">
                        External Links Section
                      </h2>
                      <p className="mt-1 text-sm text-gray-500">
                        The information you input on this form will be displayed
                        publicly on the home page second sidebar.
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-12 gap-6">
                      <div className="col-span-12 sm:col-span-12">
                        <div>
                          <Input
                            control={control}
                            errors={errors.title}
                            name="title"
                            label="Title"
                            placeholder="Enter link title"
                          />
                        </div>
                      </div>

                      <div className="col-span-12 sm:col-span-12">
                        <div>
                          <Input
                            control={control}
                            errors={errors.link}
                            name="link"
                            label="External Link"
                            placeholder="Enter external link"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="bg-sky-600 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

AdminExternalLinksForm.propTypes = {
  addExternalLink: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { addExternalLink })(
  AdminExternalLinksForm
);
