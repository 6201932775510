import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import { Link } from "react-router-dom";

function BlogItem({ blog }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % blog.images.length);
    }, 4000);

    // Clear interval on component unmount to prevent memory leaks
    return () => clearInterval(interval);
  }, [blog.images.length]);

  return (
    <div key={blog._id}>
      <div className="px-12 min-w-7xl">
        <div className="">
          <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
            {blog.images.length > 0 ? (
              <div className="">
                <img
                  className="h-64 w-full object-cover"
                  src={blog.images[currentImageIndex].url}
                  alt={`Slide ${blog + 1}`}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="relative pt-6 px-6">
        <Link to={`/blogs/${blog._id}`}>
          <h1 className="text-center text-2xl font-bold tracking-tight sm:text-3xl lg:text-4xl">
            <span className="block text-black"> {blog.title}</span>
          </h1>
        </Link>
      </div>

      <div className="px-6 py-6 text-3xl">
        <ReactQuill value={blog.description} readOnly={true} theme={"bubble"} />
      </div>
    </div>
  );
}

export default connect()(BlogItem);
