import {
  ADD_BOOK,
  DELETE_BOOK,
  GET_BOOKS,
  BOOKS_ERROR,
} from "../actions/types";

const initialState = {
  books: [],
  book: null,
  loading: true,
  error: {},
};

export default function books(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_BOOK:
      return {
        ...state,
        books: [payload, ...state.books],
        loading: false,
      };
    case DELETE_BOOK:
      return {
        ...state,
        books: state.books.filter((book) => book._id !== payload),
        loading: false,
      };
    case GET_BOOKS:
      return {
        ...state,
        books: payload,
        loading: false,
      };
    case BOOKS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
