import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getBookChapters } from "../../services/actions/bookChapters";
import BookChaptersPageContainer from "../../container/BookChaptersPage";

const BookChaptersPage = ({
  getBookChapters,
  bookChapters: { bookChapters, loading },
}) => {
  useEffect(() => {
    getBookChapters();
  }, [getBookChapters]);

  return loading || bookChapters === null ? (
    <div className="loader"></div>
  ) : (
    <Fragment>
      <BookChaptersPageContainer bookChapters={bookChapters} />
      {/* <Contact /> */}
    </Fragment>
  );
};

BookChaptersPage.propTypes = {
  getBookChapters: PropTypes.func.isRequired,
  bookChapters: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  bookChapters: state.bookChapters,
});

export default connect(mapStateToProps, { getBookChapters })(BookChaptersPage);
