import { Input } from "../components/Input";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { projectsSectionSchema } from "../data";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addProject } from "../services/actions/projects";
import { useNavigate } from "react-router-dom";
import { TextArea } from "../components/TextArea";

function AdminProjectsForm({ addProject, editProjectsData }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const defaultValues = {
    title: editProjectsData ? editProjectsData.title : "",
    author: editProjectsData ? editProjectsData.author : "",
    link: editProjectsData ? editProjectsData.link : "",
    releaseDate: editProjectsData ? editProjectsData.releaseDate : "",
    description: editProjectsData ? editProjectsData.description : "",
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(projectsSectionSchema),
  });

  useEffect(() => {
    if (editProjectsData) {
      reset({
        title: editProjectsData.title,
        author: editProjectsData.author,
        link: editProjectsData.link,
        releaseDate: editProjectsData.releaseDate,
        description: editProjectsData.description,
      });
    }
  }, [editProjectsData, reset]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (editProjectsData && editProjectsData._id) {
        const projectsData = {
          id: editProjectsData._id,
          title: data.title,
          author: data.author,
          link: data.link,
          releaseDate: data.releaseDate,
          description: data.description,
        };
        await addProject(projectsData);
      } else {
        const projectsData = {
          title: data.title,
          author: data.author,
          link: data.link,
          releaseDate: data.releaseDate,
          description: data.description,
        };
        await addProject(projectsData);
      }

      navigate(0);
    } catch (e) {
      toast.error("Error adding project, please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div>
        <main className="py-12">
          <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
            <div className="overflow-hidden rounded-lg bg-white shadow">
              <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <form
                  className="divide-y divide-gray-200 lg:col-span-12"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="py-6 px-4 sm:p-6 lg:pb-8">
                    <div>
                      <h2 className="text-lg font-medium leading-6 text-gray-900">
                        Project Deliverables Section
                      </h2>
                      <p className="mt-1 text-sm text-gray-500">
                        The information you input on this form will be displayed
                        publicly on the project deliverables page.
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-12 gap-6">
                      <div className="col-span-12 sm:col-span-12">
                        <div>
                          <Input
                            control={control}
                            errors={errors.title}
                            name="title"
                            label="Title"
                            placeholder="Enter project title"
                          />
                        </div>
                      </div>

                      <div className="col-span-12 sm:col-span-12">
                        <div>
                          <TextArea
                            row={6}
                            control={control}
                            errors={errors.description}
                            name="description"
                            label="Description"
                            placeholder="Enter project description"
                          />
                        </div>
                      </div>

                      <div className="col-span-12 sm:col-span-12">
                        <div>
                          <Input
                            control={control}
                            errors={errors.author}
                            name="author"
                            label="Author"
                            placeholder="Enter Author"
                          />
                        </div>
                      </div>

                      <div className="col-span-12 sm:col-span-12">
                        <div>
                          <Input
                            control={control}
                            errors={errors.link}
                            name="link"
                            label="Project Link"
                            placeholder="Enter link to the project"
                          />
                        </div>
                      </div>

                      <div className="col-span-12 sm:col-span-12">
                        <div>
                          <Input
                            control={control}
                            errors={errors.releaseDate}
                            name="releaseDate"
                            label="Release Date"
                            placeholder="Enter release date"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="bg-sky-600 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

AdminProjectsForm.propTypes = {
  addProject: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { addProject })(AdminProjectsForm);
