import {
  ADD_LINKS,
  DELETE_LINKS,
  GET_LINKS,
  LINKS_ERROR,
} from "../actions/types";

const initialState = {
  externalLinks: [],
  externalLink: null,
  loading: true,
  error: {},
};

export default function externalLinks(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_LINKS:
      return {
        ...state,
        externalLinks: [payload, ...state.externalLinks],
        loading: false,
      };
    case DELETE_LINKS:
      return {
        ...state,
        externalLinks: state.externalLinks.filter((externalLink) => externalLink._id !== payload),
        loading: false,
      };
    case GET_LINKS:
      return {
        ...state,
        externalLinks: payload,
        loading: false,
      };
    case LINKS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
