import { navigation } from "../data";
import { classNames } from "../utils/helpers";
import { useLocation } from "react-router-dom";

const VerticalNavigation = ({ children }) => {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/" ? (
        <>
          <img
            alt="Mountain View"
            className="backgroundImage relative lg:absolute top-0 lg:top-20"
            // className="backgroundImage absolute top-20 hidden lg:block"
            src="http://res.cloudinary.com/unitix/image/upload/v1706201529/vikq1eaigzvwixyauzh3.jpg"
          />
          <div className="min-h-full mt-6 lg:mt-36 max-w-7xl relative mx-auto">
            <div className="hidden lg:absolute lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:bg-white rounded-md mt-2 lg:mt-36 lg:pb-4">
              <div className="mt-4 pl-6 flex h-0 flex-1 flex-col overflow-y-auto">
                <nav className="space-y-1" aria-label="Sidebar">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.href === location.pathname
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "flex items-center px-4 py-4 text-lg font-medium rounded-md"
                      )}
                      aria-current={
                        item.href === location.pathname ? "page" : undefined
                      }
                    >
                      <span className="truncate">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div className="flex flex-col lg:pl-64 bg-white mt-2 lg:mt-36 rounded-md">
              <>{children}</>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="min-h-full py-12 max-w-7xl relative mx-auto">
            <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:bg-white lg:pt-5 lg:pb-4">
              <div className=" mt-24 flex h-0 flex-1 flex-col overflow-y-auto">
                <nav className="space-y-1" aria-label="Sidebar">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.href === location.pathname
                          ? "bg-gray-100 text-red-900"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "flex items-center px-4 py-4 text-lg font-medium rounded-md"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <span className="truncate">{item.name}</span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div className="flex flex-col lg:pl-64">
              <>{children}</>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VerticalNavigation;
