import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { deleteBlog } from "../services/actions/blogs";
import { makeBlogCurrent } from "../services/actions/blogs";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactQuill from "react-quill";

function AdminBlogItem({ blog, deleteBlog, makeBlogCurrent, setEditBlogData }) {
  const navigate = useNavigate();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % blog.images.length);
    }, 4000);

    // Clear interval on component unmount to prevent memory leaks
    return () => clearInterval(interval);
  }, [blog.images.length]);

  const handleEdit = (blog) => {
    setEditBlogData(blog);
    window.scrollTo(0, 0);
  };

  const handledeleteBlog = async (blogId) => {
    await deleteBlog(blogId);
  };

  const handleMakeCurrent = async (blog) => {
    await makeBlogCurrent(blog._id);
    navigate(0);
  };

  return (
    <div className="border rounded-lg py-6 px-4">
      <div className="flex justify-end">
        {blog.current ? (
          <div className="text-emerald-600 relative flex whitespace-pre-line py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
            <CheckBadgeIcon className="h-5 w-5" />
            <p className="pl-2">Current</p>
          </div>
        ) : (
          <div className="relative whitespace-pre-line py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
            <button
              type="button"
              onClick={() => handleMakeCurrent(blog)}
              className="text-emerald-600 hover:text-sky-900"
            >
              Make current
            </button>
          </div>
        )}

        <div className="relative whitespace-pre-line py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
          <button
            type="button"
            onClick={() => handleEdit(blog)}
            className="text-sky-600 hover:text-sky-900"
          >
            Edit
          </button>
        </div>
        <div className="relative whitespace-pre-line py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
          <button
            type="button"
            onClick={() => handledeleteBlog(blog._id)}
            className="text-red-600 hover:text-red-900"
          >
            Delete
          </button>
        </div>
      </div>
      <h2 className="text-center px-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl break-words">
        {blog.title}
      </h2>

      {blog && blog.images && blog.images.length > 0 ? (
        <figure className="py-4 px-2">
          <img
            className="w-full max-h-96 object-cover rounded-md"
            src={blog.images[currentImageIndex].url}
            alt={`${currentImageIndex + 1}`}
          />
        </figure>
      ) : null}

      {/* {generateImageCarousels(blog.images)} */}

      <div className="py-4 text-3xl">
        <ReactQuill value={blog.description} readOnly={true} theme={"bubble"} />
      </div>
    </div>
  );
}

AdminBlogItem.propTypes = {
  blogs: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
  deleteBlog: PropTypes.func.isRequired,
  makeBlogCurrent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { deleteBlog, makeBlogCurrent })(
  AdminBlogItem
);
