import React, { useEffect } from "react";
import VerticalNavigation from "../layout/VerticalNavigation";
import BlogItem from "./BlogItem";
import { useParams } from "react-router-dom";
import { getBlog } from "../services/actions/blogs";
import PropTypes from "prop-types";
import { connect } from "react-redux";

function BlogById({ getBlog, blogs: { blog, loading } }) {
  const { id } = useParams();
  useEffect(() => {
    getBlog(id);
  }, [getBlog, id]);

  return loading || blog === null ? (
    <div className="loader"></div>
  ) : (
    <VerticalNavigation>
      <BlogItem blog={blog} />
    </VerticalNavigation>
  );
}

BlogById.propTypes = {
  getBlog: PropTypes.func.isRequired,
  blogs: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  blogs: state.blogs,
});

export default connect(mapStateToProps, { getBlog })(BlogById);
