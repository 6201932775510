import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getBlogs } from "../../../services/actions/blogs";
import AdminBlogForm from "../../../container/AdminBlogForm";
import AdminBlogTable from "../../../container/AdminBlogTable";
import Sidebar from "../../../layout/Sidebar";

const AdminBlogsPage = ({ getBlogs, blogs: { blogs, loading } }) => {
  useEffect(() => {
    getBlogs();
  }, [getBlogs]);

  const [editBlogData, setEditBlogData] = useState();
  return loading || blogs === null ? (
    <div className="loader"></div>
  ) : (
    <Fragment>
      <Sidebar>
        <AdminBlogForm editBlogData={editBlogData} />
        <AdminBlogTable blogs={blogs} setEditBlogData={setEditBlogData} />
      </Sidebar>
    </Fragment>
  );
};

AdminBlogsPage.propTypes = {
  getBlogs: PropTypes.func.isRequired,
  blogs: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  blogs: state.blogs,
});

export default connect(mapStateToProps, { getBlogs })(AdminBlogsPage);
