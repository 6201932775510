import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getJournalArticles } from "../../../services/actions/journalArticles";
import AdminJournalArticleForm from "../../../container/AdminJournalArticleForm";
import JournalArticlesTable from "../../../container/AdminJournalArticlesTable";
import Sidebar from "../../../layout/Sidebar";

const AdminJournalArticlesPage = ({ getJournalArticles, journalArticles: { journalArticles, loading } }) => {
  useEffect(() => {
    getJournalArticles();
  }, [getJournalArticles]);

  const [editJournalArticlesData, setEditJournalArticlesData] = useState();
  return loading || journalArticles === null ? (
    <div className="loader"></div>
  ) : (
    <Fragment>
      <Sidebar>
        <AdminJournalArticleForm editJournalArticlesData={editJournalArticlesData} />
        <JournalArticlesTable journalArticles={journalArticles} setEditJournalArticlesData={setEditJournalArticlesData} />
      </Sidebar>
    </Fragment>
  );
};

AdminJournalArticlesPage.propTypes = {
  getJournalArticles: PropTypes.func.isRequired,
  journalArticles: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  journalArticles: state.journalArticles,
});

export default connect(mapStateToProps, { getJournalArticles })(AdminJournalArticlesPage);
