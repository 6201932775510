import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getBookChapters } from "../../../services/actions/bookChapters";
import AdminBookChapterForm from "../../../container/AdminBookChaptersForm";
import BookChaptersTable from "../../../container/AdminBookChaptersTable";
import Sidebar from "../../../layout/Sidebar";

const AdminBookChaptersPage = ({
  getBookChapters,
  bookChapters: { bookChapters, loading },
}) => {
  useEffect(() => {
    getBookChapters();
  }, [getBookChapters]);

  const [editBookChapterData, setEditBookChapterData] = useState();
  return loading || bookChapters === null ? (
    <div className="loader"></div>
  ) : (
    <Fragment>
      <Sidebar>
        <AdminBookChapterForm editBookChapterData={editBookChapterData} />
        <BookChaptersTable
          bookChapters={bookChapters}
          setEditBookChapterData={setEditBookChapterData}
        />
      </Sidebar>
    </Fragment>
  );
};

AdminBookChaptersPage.propTypes = {
  getBookChapters: PropTypes.func.isRequired,
  bookChapters: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  bookChapters: state.bookChapters,
});

export default connect(mapStateToProps, { getBookChapters })(
  AdminBookChaptersPage
);
