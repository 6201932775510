import { Input } from "../components/Input";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { aboutSectionSchema, formats, modules } from "../data";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import { editAboutSection } from "../services/actions/about";
import { useNavigate } from "react-router-dom";
import FileUpload from "./FileUpload";
import Axios from "axios";

function AdminAboutForm({ aboutSection, editAboutSection }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const description =
    aboutSection && aboutSection[0] ? aboutSection[0].description : "";
  const [quillValue, setQuillValue] = useState(description);

  const [Image, setImage] = useState([]);

  const updateImages = (newImages) => {
    setImage(newImages);
  };

  const defaultValues = {
    title: aboutSection ? aboutSection[0].title : "",
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(aboutSectionSchema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const images = Image;

      const aboutData = {
        id: aboutSection[0]._id,
        title: data.title,
        description: quillValue,
        images,
      };
      await editAboutSection(aboutData);
      navigate(0);
    } catch (e) {
      toast.error("Error updating details, please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteImage = (imageId) => {
    const config = {
      header: { "content-type": "application/json" },
    };
    const data = {
      imageId: imageId,
      id: aboutSection[0]._id,
    };
    Axios.post(
      `${process.env.REACT_APP_VERCEL_URL}/about/images/delete`,
      data,
      config
    ).then((response) => {
      if (response.status === 200) {
        toast.success("Deleted successfully");
      }
    });
  };
  return (
    <div>
      <main className="py-12">
        <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
              <form
                className="divide-y divide-gray-200 lg:col-span-12"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="py-6 px-4 sm:p-6 lg:pb-8">
                  <div>
                    <h2 className="text-lg font-medium leading-6 text-gray-900">
                      About Section
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      The information you input on this form will be displayed
                      publicly on the about page.
                    </p>
                  </div>

                  <div className="mt-6 grid grid-cols-12 gap-6">
                    <div className="col-span-12 sm:col-span-12">
                      <div>
                        <Input
                          control={control}
                          errors={errors.title}
                          name="title"
                          label="Title"
                          placeholder="Enter title"
                        />
                      </div>
                    </div>

                    <div className="col-span-12 sm:col-span-12">
                      <FileUpload
                        refreshFunction={updateImages}
                        uploadHeaderText={"Cover Photo"}
                        uploadText={"Upload a picture for your blog"}
                      />
                    </div>

                    <div className="sm:col-span-2 flex pt-2 pb-8 space-x-2">
                      {aboutSection &&
                      aboutSection[0] &&
                      aboutSection[0].images.length !== 0
                        ? aboutSection[0].images.map((image) => (
                            <div key={image._id} className="">
                              <img
                                style={{
                                  minWidth: "300px",
                                  width: "300px",
                                  height: "240px",
                                }}
                                src={image.url}
                                alt={`blog-${image._id}`}
                              />
                              <span className="text-center pt-2 text-red-600 flex justify-center text-sm font-bold">
                                <button
                                  onClick={() => deleteImage(image._id)}
                                  type="button"
                                >
                                  Delete
                                </button>
                              </span>
                            </div>
                          ))
                        : null}
                    </div>

                    <div className="col-span-12 sm:col-span-12">
                      <ReactQuill
                        theme="snow"
                        value={quillValue}
                        className="ql-editor-comments"
                        placeholder="Enter content for main section"
                        onChange={(value) => setQuillValue(value)}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="bg-sky-600 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

AdminAboutForm.propTypes = {
  aboutSection: PropTypes.array.isRequired,
  editAboutSection: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { editAboutSection })(AdminAboutForm);
