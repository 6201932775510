import { Input } from "../components/Input";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { blogSectionSchema, formats, modules } from "../data";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addBlog } from "../services/actions/blogs";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import FileUpload from "./FileUpload";
import Axios from "axios";

function AdminBlogForm({ addBlog, editBlogData }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const description = editBlogData ? editBlogData.description : "";
  const [quillValue, setQuillValue] = useState(description);
  const imagesProp = editBlogData ? editBlogData.images : [];

  const [Image, setImage] = useState([]);

  const defaultValues = {
    title: editBlogData ? editBlogData.title : "",
  };

  const updateImages = (newImages) => {
    setImage(newImages);
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(blogSectionSchema),
  });

  useEffect(() => {
    if (editBlogData) {
      reset({
        title: editBlogData.title,
      });
      setQuillValue(editBlogData.description);
    }
  }, [editBlogData, reset]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const images = Image;
    console.log(images);
    try {
      if (editBlogData && editBlogData._id) {
        const blogData = {
          id: editBlogData._id,
          title: data.title,
          description: quillValue,
          images,
        };
        await addBlog(blogData);
      } else {
        const blogData = {
          title: data.title,
          description: quillValue,
          images,
        };
        await addBlog(blogData);
      }
      navigate(0);
    } catch (e) {
      toast.error("Error adding blog, please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteImage = (imageId, blogId) => {
    const config = {
      header: { "content-type": "application/json" },
    };
    const data = {
      imageId: imageId,
      id: blogId._id,
    };
    Axios.post(
      `${process.env.REACT_APP_VERCEL_URL}/blogs/images/delete`,
      data,
      config
    ).then((response) => {
      if (response.status === 200) {
        toast.success("Deleted successfully");
      }
    });
  };

  return (
    <>
      <div>
        <main className="py-12">
          <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
            <div className="overflow-hidden rounded-lg bg-white shadow">
              <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <form
                  className="divide-y divide-gray-200 lg:col-span-12"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="py-6 px-4 sm:p-6 lg:pb-8">
                    <div>
                      <h2 className="text-lg font-medium leading-6 text-gray-900">
                        Blogs Section
                      </h2>
                      <p className="mt-1 text-sm text-gray-500">
                        The information you input on this form will be displayed
                        publicly on the blog page.
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-12 gap-6">
                      <div className="col-span-12 sm:col-span-12">
                        <div>
                          <Input
                            control={control}
                            errors={errors.title}
                            name="title"
                            label="Title"
                            placeholder="Enter blog title"
                          />
                        </div>
                      </div>

                      <div className="col-span-12 sm:col-span-12">
                        <FileUpload
                          refreshFunction={updateImages}
                          uploadHeaderText={"Cover Photo"}
                          uploadText={"Upload a picture for your blog"}
                        />
                      </div>
                      <div className="flex w-full justify-around overflow-y-auto sm:col-span-12 space-x-2">
                        {imagesProp && imagesProp.length !== 0
                          ? imagesProp.map((image, index) => (
                              <div key={index}>
                                <img
                                  style={{
                                    maxWidth: "300px",
                                    minWidth: "300px",
                                    width: "300px",
                                    height: "240px",
                                    display: "inline-block",
                                  }}
                                  src={image.url}
                                  alt={`blog-${index}`}
                                />
                                <span className="text-center pt-2 text-red-600 flex justify-center text-sm font-bold">
                                  <button
                                    onClick={() =>
                                      deleteImage(image._id, imagesProp._id)
                                    }
                                    type="button"
                                  >
                                    Delete
                                  </button>
                                </span>
                              </div>
                            ))
                          : null}
                      </div>

                      <div className="col-span-12 sm:col-span-12">
                        <ReactQuill
                          theme="snow"
                          value={quillValue}
                          className="ql-editor-comments"
                          placeholder="Enter content for main section"
                          onChange={(value) => setQuillValue(value)}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="bg-sky-600 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

AdminBlogForm.propTypes = {
  addBlog: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { addBlog })(AdminBlogForm);
