import axios from "axios";
import {
  ADD_CONFERENCE,
  DELETE_CONFERENCE,
  GET_CONFERENCES,
  CONFERENCE_ERROR,
} from "./types";
import toast from "react-hot-toast";

//Get all conferences in the db
export const getConferences = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_VERCEL_URL}/conferences`
    );
    dispatch({
      type: GET_CONFERENCES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CONFERENCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Add conferences
export const addConference = (conferencesData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_VERCEL_URL}/conferences/add`,
      conferencesData
    );
    dispatch({
      type: ADD_CONFERENCE,
      payload: res.data,
    });
    toast.success("Conference created successfully.");
  } catch (err) {
    dispatch({
      type: CONFERENCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    toast.error("Error adding new conference.");
  }
};

//Delete conference
export const deleteConference = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_VERCEL_URL}/conferences/${id}`);
    dispatch({
      type: DELETE_CONFERENCE,
      payload: id,
    });
    toast.success("Conferences deleted successfully.");
  } catch (err) {
    dispatch({
      type: CONFERENCE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    toast.error("Problem deleting conferences, try again.");
  }
};
