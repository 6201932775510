import { combineReducers } from "redux";
import auth from "./auth";
import books from "./books";
import bookChapters from "./bookChapters";
import journalArticles from "./journalArticles";
import conferences from "./conferences";
import blogs from "./blogs";
import externalLinks from "./links";
import aboutSection from "./about";
import projects from "./projects";

export default combineReducers({
  auth,
  books,
  bookChapters,
  journalArticles,
  conferences,
  blogs,
  externalLinks,
  aboutSection,
  projects,
});
