import React from "react";
import ReactQuill from "react-quill";
import { Link } from "react-router-dom";

function OtherBlogs({ blog }) {
  return (
    <Link to={`/blogs/${blog._id}`} key={blog._id} className="pt-4 break-words cursor-pointer">
      <div className="mt-2 block">
        <p className="pl-4 text-xl font-semibold text-gray-900 break-words">
          {blog.title}
        </p>

        <div className="">
          <ReactQuill
            value={blog.description}
            readOnly={true}
            theme={"bubble"}
            className="ql-editor-blogs"
          />
        </div>
      </div>
    </Link>
  );
}

export default OtherBlogs;
