import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deleteBookChapter } from "../services/actions/bookChapters";

function BookChaptersTable({
  bookChapters,
  setEditBookChapterData,
  deleteBookChapters,
}) {
  const handleEdit = (bookChapter) => {
    setEditBookChapterData(bookChapter);
    window.scrollTo(0, 0);
  };

  const handleDeleteBookChapter = async (bookChapterId) => {
    await deleteBookChapter(bookChapterId);
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Books List</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the book chapters in your portfolio including their
            title, authors, links, description and release date.
          </p>
        </div>
      </div>
      <div className="py-8 flex flex-col">
        <div className="">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Author
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Release Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Link
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                    >
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {bookChapters.map((bookChapter) => (
                    <tr key={bookChapter._id}>
                      <td className="items-center align-top whitespace-pre-line py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        {bookChapter.title}
                      </td>
                      <td className="items-center align-top whitespace-pre-line px-3 py-4 text-sm text-gray-500">
                        {bookChapter.author}
                      </td>
                      <td className="items-center align-top whitespace-pre-line py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        {bookChapter.releaseDate}
                      </td>
                      <td className="items-center align-top whitespace-pre-line px-3 py-4 text-sm text-gray-500">
                        {bookChapter.link}
                      </td>
                      <td className="items-center align-top whitespace-pre-line px-3 py-4 text-sm text-gray-500">
                        {bookChapter.description}
                      </td>
                      <td className="relative whitespace-pre-line py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                        <button
                          type="button"
                          onClick={() => handleEdit(bookChapter)}
                          className="text-sky-600 hover:text-sky-900"
                        >
                          Edit
                        </button>
                      </td>
                      <td className="relative whitespace-pre-line py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                        <button
                          type="button"
                          onClick={() =>
                            handleDeleteBookChapter(bookChapter._id)
                          }
                          className="text-red-600 hover:text-red-900"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BookChaptersTable.propTypes = {
  bookChapters: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
  deleteBookChapter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { deleteBookChapter })(
  BookChaptersTable
);
