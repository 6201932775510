import { ArchiveBoxXMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

function EmptyComponent({ text }) {
  return (
    <div className="sm:pl-64 sm:pr-64 pt-20 text-center">
      <ArchiveBoxXMarkIcon
        className="h-24 w-48 sm:h-64 sm:w-64 text-center flex justify-center mx-auto"
        aria-hidden="true"
      />
      <h2 className="pt-3 font-bold text-2xl ">{text}</h2>
    </div>
  );
}

export default EmptyComponent;
