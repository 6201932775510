import {
  ADD_JOURNAL_ARTICLE,
  DELETE_JOURNAL_ARTICLE,
  GET_JOURNAL_ARTICLES,
  JOURNAL_ARTICLE_ERROR,
} from "../actions/types";

const initialState = {
  journalArticles: [],
  journalArticle: null,
  loading: true,
  error: {},
};

export default function journalArticles(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_JOURNAL_ARTICLE:
      return {
        ...state,
        journalArticles: [payload, ...state.journalArticles],
        loading: false,
      };
    case DELETE_JOURNAL_ARTICLE:
      return {
        ...state,
        journalArticles: state.journalArticles.filter((journalArticle) => journalArticle._id !== payload),
        loading: false,
      };
    case GET_JOURNAL_ARTICLES:
      return {
        ...state,
        journalArticles: payload,
        loading: false,
      };
    case JOURNAL_ARTICLE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
