import { connect } from "react-redux";
import PropTypes from "prop-types";
import VerticalNavigation from "../layout/VerticalNavigation";
import EmptyComponent from "./EmptyComponent";

function ConferencesContainer({ conferences }) {
  return (
    <VerticalNavigation>
      <div className="pt-4 px-12">
        <div className="relative mx-auto max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Conferences
            </h2>
            <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:items-center lg:gap-5">
              <p className="text-xl text-gray-500">
                All conferences I attended and their respective dates.
              </p>
            </div>
          </div>
          <div className="mt-6 grid gap-16 pt-10 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
            {conferences.map((conference) => (
              <div key={conference._id}>
                <p className="text-sm text-gray-500">
                  <p>{conference.conferenceDate}</p>
                </p>
                <div className="mt-2 block">
                  <p className="text-xl font-semibold text-gray-900 break-words">
                    {conference.title}
                  </p>
                  <p className="mt-3 text-base text-gray-500 break-words">
                    {conference.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {conferences.length === 0 && (
          <EmptyComponent text="Conference coming soon" />
        )}
      </div>
    </VerticalNavigation>
  );
}

ConferencesContainer.propTypes = {
  conferences: PropTypes.array.isRequired,
};

export default connect()(ConferencesContainer);
