import axios from "axios";
import { ADD_BOOK, DELETE_BOOK, GET_BOOKS, BOOKS_ERROR } from "./types";
import toast from "react-hot-toast";

//Get all books in the db
export const getBooks = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_VERCEL_URL}/books`);
    dispatch({
      type: GET_BOOKS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: BOOKS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Add book
export const addBook = (bookData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_VERCEL_URL}/books/add`,
      bookData
    );
    dispatch({
      type: ADD_BOOK,
      payload: res.data,
    });
    toast.success("Book created successfully.");
  } catch (err) {
    dispatch({
      type: BOOKS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    toast.error("Error adding new book.");
  }
};

//Delete book
export const deleteBook = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_VERCEL_URL}/books/${id}`);
    dispatch({
      type: DELETE_BOOK,
      payload: id,
    });
    toast.success("Book deleted successfully.");
  } catch (err) {
    dispatch({
      type: BOOKS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    toast.error("Problem deleting book, try again.");
  }
};
