import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getExternalLinks } from "../../../services/actions/links";
import Sidebar from "../../../layout/Sidebar";
import AdminExternalLinksTable from "../../../container/AdminExternalLinksTable";
import AdminExternalLinksForm from "../../../container/AdminExternalLinksForm";

const AdminExternalLinksPage = ({
  getExternalLinks,
  externalLinks: { externalLinks, loading },
}) => {
  useEffect(() => {
    getExternalLinks();
  }, [getExternalLinks]);

  const [editExternalLinksData, setEditExternalLinksData] = useState();

  return loading || externalLinks === null ? (
    <div className="loader"></div>
  ) : (
    <Fragment>
      <Sidebar>
        <AdminExternalLinksForm editExternalLinksData={editExternalLinksData} />
        <AdminExternalLinksTable
          externalLinks={externalLinks}
          setEditExternalLinksData={setEditExternalLinksData}
        />
      </Sidebar>
    </Fragment>
  );
};

AdminExternalLinksPage.propTypes = {
  getExternalLinks: PropTypes.func.isRequired,
  externalLinks: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  externalLinks: state.externalLinks,
});

export default connect(mapStateToProps, { getExternalLinks })(
  AdminExternalLinksPage
);
