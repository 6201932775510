import * as yup from "yup";

export const navigation = [
  { name: "Home", href: "/" },
  { name: "Books", href: "/books" },
  { name: "Book chapters", href: "/book-chapters" },
  { name: "Journal articles", href: "/articles" },
  { name: "Projects deliverables", href: "/projects" },
  { name: "Conferences", href: "/conferences" },
  { name: "About", href: "/about" },
];

export const adminNavigation = [
  { name: "Books", href: "/admin/books" },
  { name: "Book chapters", href: "/admin/book-chapters" },
  { name: "Journal articles", href: "/admin/articles" },
  { name: "Project deliverables", href: "/admin/projects" },
  { name: "Conferences", href: "/admin/conferences" },
  { name: "Blogs", href: "/admin/blogs" },
  { name: "External Links", href: "/admin/links" },
  { name: "About Section", href: "/admin/about" },
];

export const signInSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Please enter your email address"),
  password: yup
    .string()
    .required("Please enter your password")
    .min(8, "Minimum of 8 characters required"),
});

export const bookSectionSchema = yup.object().shape({
  title: yup
    .string()
    .min(4, "Minimum of 4 characters required")
    .required("Please enter the title"),
  author: yup
    .string()
    .required("Please enter the author")
    .min(4, "Minimum of 4 characters required"),
  link: yup
    .string()
    .required("Please enter the book link")
    .min(4, "Minimum of 4 characters required"),
  releaseDate: yup
    .string()
    .required("Please enter the release date")
    .min(4, "Minimum of 4 characters required"),
  description: yup
    .string()
    .required("Please enter the description")
    .min(4, "Minimum of 10 characters required"),
});

export const bookChaptersSectionSchema = yup.object().shape({
  title: yup
    .string()
    .min(4, "Minimum of 4 characters required")
    .required("Please enter the title"),
  author: yup
    .string()
    .required("Please enter the author")
    .min(4, "Minimum of 4 characters required"),
  link: yup
    .string()
    .required("Please enter the book chapter link")
    .min(4, "Minimum of 4 characters required"),
  releaseDate: yup
    .string()
    .required("Please enter the release date")
    .min(4, "Minimum of 4 characters required"),
  description: yup
    .string()
    .required("Please enter the description")
    .min(4, "Minimum of 10 characters required"),
});

export const journalArticlesSectionSchema = yup.object().shape({
  title: yup
    .string()
    .min(4, "Minimum of 4 characters required")
    .required("Please enter the title"),
  author: yup
    .string()
    .required("Please enter the author")
    .min(4, "Minimum of 4 characters required"),
  link: yup
    .string()
    .required("Please enter the journal article link")
    .min(4, "Minimum of 4 characters required"),
  releaseDate: yup
    .string()
    .required("Please enter the release date")
    .min(4, "Minimum of 4 characters required"),
  description: yup
    .string()
    .required("Please enter the description")
    .min(4, "Minimum of 10 characters required"),
});

export const projectsSectionSchema = yup.object().shape({
  title: yup
    .string()
    .min(4, "Minimum of 4 characters required")
    .required("Please enter the title"),
  author: yup
    .string()
    .required("Please enter the author")
    .min(4, "Minimum of 4 characters required"),
  link: yup
    .string()
    .required("Please enter the journal article link")
    .min(4, "Minimum of 4 characters required"),
  releaseDate: yup
    .string()
    .required("Please enter the release date")
    .min(4, "Minimum of 4 characters required"),
  description: yup
    .string()
    .required("Please enter the description")
    .min(4, "Minimum of 10 characters required"),
});

export const conferencesSectionSchema = yup.object().shape({
  title: yup
    .string()
    .min(4, "Minimum of 4 characters required")
    .required("Please enter the title"),
  conferenceDate: yup
    .string()
    .required("Please enter the conference date")
    .min(4, "Minimum of 4 characters required"),
  description: yup
    .string()
    .required("Please enter the description")
    .min(4, "Minimum of 10 characters required"),
});

export const externalLinksSectionSchema = yup.object().shape({
  title: yup
    .string()
    .min(4, "Minimum of 4 characters required")
    .required("Please enter the title"),
  link: yup
    .string()
    .required("Please enter the book chapter link")
    .min(4, "Minimum of 4 characters required"),
});

export const contactSchema = yup.object().shape({
  fullName: yup
    .string()
    .min(4, "Minimum of 3 characters required")
    .required("Please enter your full name"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Please enter your email address"),
  phoneNumber: yup
    .string()
    .required("Please enter the book chapter link")
    .min(4, "Minimum of 4 characters required"),
  message: yup
    .string()
    .required("Please enter the release date")
    .min(4, "Minimum of 10 characters required"),
});

export const blogSectionSchema = yup.object().shape({
  title: yup
    .string()
    .min(4, "Minimum of 4 characters required")
    .required("Please enter the title"),
});

export const aboutSectionSchema = yup.object().shape({
  title: yup
    .string()
    .min(4, "Minimum of 4 characters required")
    .required("Please enter the title"),
});

export const modules = {
  toolbar: [
    ["bold", "italic", "underline", "blockquote", "code-block", "strike"],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "code-block",
  "align",
  "direction",
  "color",
  "background",
  "script",
  "super",
  "sub",
];

export const socialLinks = [
  {
    name: "Twitter",
    href: "https://x.com/bankolefalade?s=20",
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
      </svg>
    ),
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/in/bankolefalade/",
    icon: (props) => (
      <svg
        fill="currentColor"
        height="24"
        width="24"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 455 455"
        {...props}
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M246.4,204.35v-0.665c-0.136,0.223-0.324,0.446-0.442,0.665H246.4z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0,0v455h455V0H0z M141.522,378.002H74.016V174.906h67.506V378.002z
 M107.769,147.186h-0.446C84.678,147.186,70,131.585,70,112.085c0-19.928,15.107-35.087,38.211-35.087
c23.109,0,37.31,15.159,37.752,35.087C145.963,131.585,131.32,147.186,107.769,147.186z M385,378.002h-67.524V269.345
c0-27.291-9.756-45.92-34.195-45.92c-18.664,0-29.755,12.543-34.641,24.693c-1.776,4.34-2.24,10.373-2.24,16.459v113.426h-67.537
c0,0,0.905-184.043,0-203.096H246.4v28.779c8.973-13.807,24.986-33.547,60.856-33.547c44.437,0,77.744,29.02,77.744,91.398V378.002
z"
          />
        </g>
      </svg>
    ),
  },
];
