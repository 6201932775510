import {
  ADD_CONFERENCE,
  DELETE_CONFERENCE,
  GET_CONFERENCES,
  CONFERENCE_ERROR,
} from "../actions/types";

const initialState = {
  conferences: [],
  conference: null,
  loading: true,
  error: {},
};

export default function conferences(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_CONFERENCE:
      return {
        ...state,
        conferences: [payload, ...state.conferences],
        loading: false,
      };
    case DELETE_CONFERENCE:
      return {
        ...state,
        conferences: state.conferences.filter((conference) => conference._id !== payload),
        loading: false,
      };
    case GET_CONFERENCES:
      return {
        ...state,
        conferences: payload,
        loading: false,
      };
    case CONFERENCE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
