import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAboutSection } from "../../services/actions/about";
import AboutPageContainer from "../../container/AboutPage";

const AboutPage = ({
  getAboutSection,
  aboutSection: { aboutSection, loading },
}) => {
  useEffect(() => {
    getAboutSection();
  }, [getAboutSection]);

  return loading || aboutSection.length === 0 ? (
    <div className="loader"></div>
  ) : (
    <Fragment>
      <AboutPageContainer aboutSection={aboutSection} />
    </Fragment>
  );
};

AboutPage.propTypes = {
  getAboutSection: PropTypes.func.isRequired,
  aboutSection: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  aboutSection: state.aboutSection,
});

export default connect(mapStateToProps, { getAboutSection })(AboutPage);
