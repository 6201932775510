import {
  ADD_BOOK_CHAPTERS,
  DELETE_BOOK_CHAPTERS,
  GET_BOOK_CHAPTERS,
  BOOK_CHAPTERS_ERROR,
} from "../actions/types";

const initialState = {
  bookChapters: [],
  bookChapter: null,
  loading: true,
  error: {},
};

export default function bookChapters(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_BOOK_CHAPTERS:
      return {
        ...state,
        bookChapters: [payload, ...state.bookChapters],
        loading: false,
      };
    case DELETE_BOOK_CHAPTERS:
      return {
        ...state,
        bookChapters: state.bookChapters.filter((bookChapter) => bookChapter._id !== payload),
        loading: false,
      };
    case GET_BOOK_CHAPTERS:
      return {
        ...state,
        bookChapters: payload,
        loading: false,
      };
    case BOOK_CHAPTERS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
