import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getBooks } from "../../services/actions/books";
import BooksPageContainer from "../../container/BooksPage";

const BooksPage = ({ getBooks, books: { books, loading } }) => {
  useEffect(() => {
    getBooks();
  }, [getBooks]);

  return loading || books === null ? (
    <div className="loader"></div>
  ) : (
    <Fragment>
      <BooksPageContainer books={books} />
      {/* <Contact /> */}
    </Fragment>
  );
};

BooksPage.propTypes = {
  getBooks: PropTypes.func.isRequired,
  books: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  books: state.books,
});

export default connect(mapStateToProps, { getBooks })(BooksPage);
