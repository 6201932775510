import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import VerticalNavigation from "../layout/VerticalNavigation";
import { useEffect, useState } from "react";

function AboutPageContainer({ aboutSection }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % aboutSection[0].images.length
      );
    }, 4000);

    // Clear interval on component unmount to prevent memory leaks
    return () => clearInterval(interval);
  }, [aboutSection]);
  return (
    <VerticalNavigation>
      <div className="px-12">
        <div className="">
          <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
            {aboutSection[0].images.length > 0 ? (
              <div className="">
                <img
                  className="h-64 w-screen object-cover min-w-screen"
                  src={aboutSection[0].images[currentImageIndex].url}
                  alt={`Slide ${currentImageIndex + 1}`}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="relative pt-6">
        <h1 className="mt-2 text-center text-2xl font-bold tracking-tight sm:text-3xl lg:text-4xl">
          <span className="block text-black"> {aboutSection[0].title}</span>
        </h1>
      </div>

      <div className="px-6 py-6 text-3xl">
        <ReactQuill
          value={aboutSection[0].description}
          readOnly={true}
          theme={"bubble"}
        />
      </div>
    </VerticalNavigation>
  );
}

AboutPageContainer.propTypes = {
  aboutSection: PropTypes.array.isRequired,
};

export default connect()(AboutPageContainer);
