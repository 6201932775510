import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deleteConference } from "../services/actions/conferences";

function AdminConferenceTable({
  conferences,
  setEditConferenceData,
  deleteConference,
}) {
  const handleEdit = (conference) => {
    setEditConferenceData(conference);
    window.scrollTo(0, 0);
  };

  const handledeleteConference = async (conferenceId) => {
    await deleteConference(conferenceId);
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Conferences List
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the conferences in your portfolio including their
            title, description and date.
          </p>
        </div>
      </div>
      <div className="py-8 flex flex-col">
        <div className="">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8"
                    >
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {conferences.map((conference) => (
                    <tr key={conference._id}>
                      <td className="items-center align-top whitespace-pre-line py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        {conference.title}
                      </td>
                      <td className="items-center align-top whitespace-pre-line py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        {conference.conferenceDate}
                      </td>
                      <td className="items-center align-top whitespace-pre-line px-3 py-4 text-sm text-gray-500">
                        {conference.description}
                      </td>
                      <td className="relative whitespace-pre-line py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                        <button
                          type="button"
                          onClick={() => handleEdit(conference)}
                          className="text-sky-600 hover:text-sky-900"
                        >
                          Edit
                        </button>
                      </td>
                      <td className="relative whitespace-pre-line py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                        <button
                          type="button"
                          onClick={() => handledeleteConference(conference._id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AdminConferenceTable.propTypes = {
  conferences: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
  deleteConference: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { deleteConference })(
  AdminConferenceTable
);
