import { connect } from "react-redux";
import PropTypes from "prop-types";
import VerticalNavigation from "../layout/VerticalNavigation";
import BlogItem from "./BlogItem";
import OtherBlogs from "./OtherBlogs";

function BlogsContainer({ blogs, externalLinks }) {
  return (
    <VerticalNavigation>
      <div className="flex">
        <main className="flex-1">
          {blogs.map(
            (blog) => blog.current && <BlogItem blog={blog} key={blog._id} />
          )}

          <div className="px-6 relative mx-auto max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl">
            <div>
              <h2 className="pl-4 pb-2 text-xl font-bold tracking-tight text-gray-900 sm:text-xl">
                More blogs
              </h2>
            </div>

            <div className="grid gap-16 lg:grid-cols-1 lg:gap-x-5 lg:gap-y-6 divide-y">
              {blogs.map(
                (blog) =>
                  (blog.current === false || !blog.current) && (
                    <OtherBlogs blog={blog} key={blog._id} />
                  )
              )}
            </div>
          </div>
        </main>

        {externalLinks.length > 0 && (
          <aside className="w-1/4 p-4  hidden sm:block">
            <div className="space-y-2 py-5">
              <h1 className="text-xl font-bold">External links</h1>
              {externalLinks.map((externalLink) => (
                <div className="py-4" key={externalLink._id}>
                  <a
                    href={externalLink.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" underline"
                  >
                    {externalLink.title}
                  </a>
                </div>
              ))}
            </div>
          </aside>
        )}
      </div>
    </VerticalNavigation>
  );
}

BlogsContainer.propTypes = {
  blogs: PropTypes.array.isRequired,
  externalLinks: PropTypes.array.isRequired,
};

export default connect()(BlogsContainer);
