import axios from "axios";
import {
  ADD_PROJECT,
  DELETE_PROJECT,
  GET_PROJECTS,
  PROJECT_ERROR,
} from "./types";
import toast from "react-hot-toast";

//Get all Projects in the db
export const getProjects = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_VERCEL_URL}/projects`);
    dispatch({
      type: GET_PROJECTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROJECT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Add Project
export const addProject = (projectData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_VERCEL_URL}/projects/add`,
      projectData
    );
    dispatch({
      type: ADD_PROJECT,
      payload: res.data,
    });
    toast.success("Project created successfully.");
  } catch (err) {
    dispatch({
      type: PROJECT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    toast.error("Error adding new Project.");
  }
};

//Delete Project
export const deleteProject = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_VERCEL_URL}/projects/${id}`);
    dispatch({
      type: DELETE_PROJECT,
      payload: id,
    });
    toast.success("Project deleted successfully.");
  } catch (err) {
    dispatch({
      type: PROJECT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    toast.error("Problem deleting Project, try again.");
  }
};
