import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getJournalArticles } from "../../services/actions/journalArticles";
import JournalArticlesContainer from "../../container/JournalArticles";

const JournalArticles = ({
  getJournalArticles,
  journalArticles: { journalArticles, loading },
}) => {
  useEffect(() => {
    getJournalArticles();
  }, [getJournalArticles]);

  return loading || journalArticles === null ? (
    <div className="loader"></div>
  ) : (
    <Fragment>
      <JournalArticlesContainer journalArticles={journalArticles} />
    </Fragment>
  );
};

JournalArticles.propTypes = {
  getJournalArticles: PropTypes.func.isRequired,
  journalArticles: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  journalArticles: state.journalArticles,
});

export default connect(mapStateToProps, { getJournalArticles })(
  JournalArticles
);
