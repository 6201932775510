import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAboutSection } from "../../../services/actions/about";
import Sidebar from "../../../layout/Sidebar";
import AdminAboutSectionForm from "../../../container/AdminAboutSectionForm";

const AdminAboutPage = ({
  getAboutSection,
  aboutSection: { aboutSection, loading },
}) => {
  useEffect(() => {
    getAboutSection();
  }, [getAboutSection]);

  return loading || aboutSection.length === 0 ? (
    <div className="loader"></div>
  ) : (
    <Fragment>
      <Sidebar>
        <AdminAboutSectionForm aboutSection={aboutSection} />
      </Sidebar>
    </Fragment>
  );
};

AdminAboutPage.propTypes = {
  getAboutSection: PropTypes.func.isRequired,
  aboutSection: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  aboutSection: state.aboutSection,
});

export default connect(mapStateToProps, { getAboutSection })(AdminAboutPage);
