import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3CenterLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { adminNavigation } from "../data";
import { classNames } from "../utils/helpers";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../services/actions/auth";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Sidebar = ({ children, logout }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate("/");
  };
  return (
    <>
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-sky-700 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <Link to="/">
                      <h1 className="font-extrabold text-2xl text-white">
                        Bankole Falade
                      </h1>
                    </Link>
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="px-2">
                      <div className="space-y-1">
                        {adminNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.href === location.pathname
                                ? "bg-gray-100 text-gray-900"
                                : "text-white hover:text-gray-900 hover:bg-gray-50",
                              "group flex items-center px-4 py-4 text-base leading-5 font-medium rounded-lg"
                            )}
                          >
                            {item.name}
                          </a>
                        ))}
                        <button
                          type="button"
                          onClick={() => handleLogout()}
                          className="w-full hover:text-gray-900 hover:bg-gray-50 text-white text-base group flex items-center px-4 py-4 leading-5 font-medium rounded-lg"
                        >
                          Logout
                        </button>
                      </div>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:border-r lg:border-gray-200 lg:bg-sky-700 lg:pt-5 lg:pb-4">
          <div className="flex flex-shrink-0 items-center px-6">
            <Link to="/">
              <h1 className="font-extrabold text-lg text-white">
                Bankole Falade
              </h1>
            </Link>
          </div>
          <div className="mt-6 flex h-0 flex-1 flex-col overflow-y-auto">
            <nav className="mt-6 px-3">
              <div className="space-y-1">
                {adminNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.href === location.pathname
                        ? "bg-gray-200 text-gray-900"
                        : "text-white hover:text-gray-900 hover:bg-gray-50",
                      "group flex items-center px-4 py-4 text-lg font-medium rounded-md"
                    )}
                  >
                    {item.name}
                  </Link>
                ))}
                <button
                  type="button"
                  onClick={() => handleLogout()}
                  className="w-full hover:text-gray-900 hover:bg-gray-50 text-white text-lg group flex items-center px-4 py-4 leading-5 font-medium rounded-lg"
                >
                  Logout
                </button>
              </div>
            </nav>
          </div>
        </div>
        {/* Main column */}
        <div className="flex flex-col lg:pl-64">
          {/* Search header */}
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:hidden">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <>{children}</>
        </div>
      </div>
    </>
  );
};

Sidebar.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default connect(null, { logout })(Sidebar);
