import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deleteBlog } from "../services/actions/blogs";
import EmptyComponent from "./EmptyComponent";
import { makeBlogCurrent } from "../services/actions/blogs";
import AdminBlogItem from "./AdminBlogItem";

function AdminBlogTable({ blogs, setEditBlogData }) {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Blogs List</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the blogs in your portfolio including their title,
            description and date.
          </p>
        </div>
      </div>

      <div className="pt-8 space-y-8">
        {blogs.length > 0 ? (
          blogs.map((blog) => (
            <AdminBlogItem blog={blog} setEditBlogData={setEditBlogData} key={blog._id}/>
          ))
        ) : (
          <EmptyComponent text="Add a new blog" />
        )}
      </div>
    </div>
  );
}

AdminBlogTable.propTypes = {
  blogs: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
  deleteBlog: PropTypes.func.isRequired,
  makeBlogCurrent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { deleteBlog, makeBlogCurrent })(
  AdminBlogTable
);
