import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getProjects } from "../../../services/actions/projects";
import Sidebar from "../../../layout/Sidebar";
import AdminProjectsForm from "../../../container/AdminProjectsForm";
import AdminProjectsTable from "../../../container/AdminProjectsTable";

const AdminProjectsPage = ({
  getProjects,
  projects: { projects, loading },
}) => {
  useEffect(() => {
    getProjects();
  }, [getProjects]);

  const [editProjectsData, setEditProjectsData] = useState();
  return loading || projects === null ? (
    <div className="loader"></div>
  ) : (
    <Fragment>
      <Sidebar>
        <AdminProjectsForm editProjctsData={editProjectsData} />
        <AdminProjectsTable
          projects={projects}
          setEditProjectsData={setEditProjectsData}
        />
      </Sidebar>
    </Fragment>
  );
};

AdminProjectsPage.propTypes = {
  getProjects: PropTypes.func.isRequired,
  projects: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  projects: state.projects,
});

export default connect(mapStateToProps, { getProjects })(AdminProjectsPage);
